import React, { useState, useEffect, Component } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Badge from "react-bootstrap/Badge";
//import Overlay from "react-bootstrap/Overlay";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
//import DropdownButton from "react-bootstrap/DropdownButton";
//import { Card as SwipeCard, CardWrapper } from "react-swipeable-cards";
//import ReactCardFlip from "react-card-flip";
import "./Home.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from 'react-bootstrap/ListGroup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faHome, faChevronLeft, faChevronRight, faList, faImage, faPencilAlt, faEye, faUndo, faSearch, faTrash, faPlus, faTrashRestore} from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp, faThumbsDown, faSave, faTimesCircle, faCircle} from '@fortawesome/free-regular-svg-icons';
//import majorCodec from '../components/MajorCodec';
//import CardViewer from '../components/CardViewer';
import MDViewer from '../components/MDViewer';
//const reactStringReplace = require('react-string-replace');
//const fetch = require('node-fetch');
const CryptoJS = require("crypto-js");
/* global localStorage */ 

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const linesForTopAdjust = 10; // vertical scroll doesn't work right if align-items-center, the number 10 is chosen experimentally

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFlipped: false,
      isPrevDisabled: true,
      isNextDisabled: true,
      isListMode: false,
      isListEnabled: false,
      isEditMode: false,
      isEditEnabled: true,
      isSaveMode: false,
      isSearchMode: false,
      searchText: undefined,
      linkMode: 0, // 1: unlink,uncloze, divisible by 2: link, divisible by 3: cloze, divisible by 5: encrypt e.g.: 1-unclozed,unlinked 2-linked,unclozed, 3-clozed,unlinked, 6-linked+clozed,
      show_card_index: 0,
      cardHistory: [], // history within the currently loaded deck of cards
      deckHistory: [], // history of decks, pushed onLink and popped when the deck history is empty
      notes: [],
      editedCard: {},
      config: undefined,
      allTags: [],
      selectedTags: [],
      isShowArchived: false,
      enteredTag: undefined,
    };
  }
  
  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
  
    try {
      const selectedTags = ((l) => (l && l.length) ? JSON.parse(l): [])(localStorage.getItem('selectedTags'));
      const notes = await this.getNotes('', selectedTags);
      const config = await this.getConfig();
      //console.log('config', config)
      this.setState({
        notes,
        config: config,
        allTags: (config && config.tags) ? config.tags.sort() : [],
        //selectedTags: selectedTags ? JSON.parse(selectedTags) : this.state.selectedTags,
        selectedTags: selectedTags,
      });
    } catch (e) {
      alert(e);
    }
  
    this.setState({ isLoading: false });
    this.setCardIndex();
  }
  
  goBack() {
    let newCardHistory = this.state.cardHistory;
    let notes = this.state.notes;
    let newDeckHistory = this.state.deckHistory;
    let i;
    if(newCardHistory.length) { 
      i = newCardHistory.pop();
    } else if (newDeckHistory.length) {
      const deckHistory = newDeckHistory.pop();
      i = deckHistory.show_card_index;
      newCardHistory = deckHistory.cardHistory;
      notes = deckHistory.notes;
    } else {
      i = 0;
    }
    this.setState({
      show_card_index: i > 0 ? (i < notes.length ? i : (notes.length - 1)) : 0,
      isNextDisabled: i < (notes.length - 1) ? false : true,
      isPrevDisabled: (newCardHistory.length + newDeckHistory.length) > 0 ? false : true,
      cardHistory: newCardHistory,
      notes,
      isListEnabled: notes.length > 1 ? true : false,
    });
  }
  
  setCardIndex(i=0) {
    let newCardHistory = this.state.cardHistory;
    if (i > 0) {
      newCardHistory.push(this.state.show_card_index);
    }
    this.setState({
      show_card_index: i > 0 ? (i < this.state.notes.length ? i : (this.state.notes.length - 1)) : 0,
      isNextDisabled: i < (this.state.notes.length - 1) ? false : true,
      isPrevDisabled: (this.state.cardHistory.length + this.state.deckHistory.length) > 0 ? false : true, //i > 0 ? false : true,
      cardHistory: newCardHistory,
      isListEnabled: this.state.notes.length > 1 ? true : false,
    });
  }

  getConfig() {
    const r = API.get("memonote", "/config");
    if (r.message) {
      console.log("API returned error: " + r.message);
      return {};
    }
    return r;
  }
  
  //getNotes(link='', tags=[]) {
   // tags = tags.length ? tags : this.state.selectedTags;
  getNotes(link='', tags=this.state.selectedTags) {
    const r = API.get("memonote", "/card?limit=100" +
    (link.length > 0 ? "&link="+link : "") +
    //(tags.length > 0 ? "&" + tags : "") +
    (tags.length > 0 ? "&" + tags.map(t=>`tag=${t}`).join('&') : "") +
    (this.state.isShowArchived ? "&archive=1" : "")
    );
    if (r.message) {
      console.log("API returned error: " + r.message);
      return [];
    }
    return r;
  }
  
  getNote(id) {
    return API.get("memonote", "/card/" + id);
  }
  
  /*
  getNoteByLink(link) {
    const r = API.get("memonote", `/card?limit=100&link=${link}`);
    if (r.message) {
      console.log("API returned error: " + r.message);
      return [];
    }
    return r;
  }
  */
  
  putNoteFeedback(id, strength) {
    return API.put("memonote", "/card/" + id + "?strength=" + strength, {});
  }

  putNoteContent(id, title, content, tags, complexity) {
    //console.log("memonote", "/card/" + id, {body: {title: title, content: content, tags: tags, complexity: complexity}});
    return API.put("memonote", "/card/" + id, {body: {title: title, content: content, tags: tags, complexity: complexity}});
  }

  putArchiveNote(id, action) {
    return API.put("memonote", "/card/" + id + "?action=" + action);
  }

  onPrevClick() {
    this.goBack();
  }
 
  onNextClick() {
    this.setCardIndex(this.state.show_card_index + 1);
  }
  
  onItemClick(i) {
    this.setCardIndex(i);
    this.setState({
      //show_card_index: i,
      isFlipped: false,
      isListMode: false
    });
  }
  
  onHomeClick = async (event, refreshConfig=false) => {
    //event.preventDefault();
    //event.stopPropagation();
    try {
      const notes = await this.getNotes();
      if(!notes.length) {
        alert("No cards found");
        return;
      }
      const config = (!this.state.config || refreshConfig) ? await this.getConfig() : this.state.config;
      this.setState({
        notes,
        cardHistory: [],
        deckHistory: [], // ATTENTION: click on Note clears up deep history
        isFlipped: false,
        isListEnabled: notes.length > 1 ? true : false,
        show_card_index: 0,
        config: config,
        allTags: (config && config.tags) ? config.tags.sort() : [],
        });
    } catch (e) {
      alert('Error on Home Click: ' + e);
    }
    this.setCardIndex(0);
  }
  
  onLinkClick = async (event, link) => {
    //console.log('onLinkClick', link)
    event.preventDefault();
    event.stopPropagation();
    const newDeckHistory = this.state.deckHistory;
    newDeckHistory.push({notes: this.state.notes, show_card_index: this.state.show_card_index, cardHistory: this.state.cardHistory});
    //console.log('onLinkClick newDeckHistory', newDeckHistory.length)
    try {
      const notes = await this.getNotes(link=link); //getNoteByLink(link);
      if(!notes.length) {
        alert("No cards found");
        return;
      }
      //console.log('notes', notes)
      this.setState({
        notes,
        cardHistory: [],
        deckHistory: newDeckHistory,
        isFlipped: false,
        isListMode: notes.length > 1 ? true : false,
        isListEnabled: notes.length > 1 ? true : false,
        show_card_index: 0,
        });
    } catch (e) {
      alert('Error on Link Click: ' + e);
    }
    this.setCardIndex(0);
  }
 
  onFeedbackClick = async (event, strength=0) => {
    event.preventDefault();
    event.stopPropagation();
    const card_id = this.state.notes[this.state.show_card_index].card_id;
    var updated_note = {};
    const clicked_card_index = this.state.show_card_index;
    this.setState({
      //show_card_index: this.state.show_card_index + 1,
      isFlipped: false
    });
    try {
        await this.putNoteFeedback(card_id, strength);
        const notes = await this.getNote(card_id);
        updated_note = notes[0];
    } catch (e) {
      alert(e);
      //console.log(e);
      return;
    } 
    const updated_notes = this.state.notes;
    updated_notes[clicked_card_index] = updated_note;
    this.setState({
      notes: updated_notes
    });
    this.onNextClick();
  }
  
  onSaveClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    //const card_id = this.state.notes[this.state.show_card_index].card_id;
    const updated_notes = this.state.notes;
    const card_id = this.state.editedCard.card_id;
    const title = this.state.editedCard.title;
    const content = this.state.editedCard.content;
    const complexity = this.state.editedCard.complexity/100.0;
    const tags = this.state.editedCard.tags.join(';');
    let edited_card_index = this.state.show_card_index;
    if (card_id === '0') {
      updated_notes.unshift(this.state.editedCard);
      edited_card_index = 0;
    }
    var updated_note = {};
    try {
        const response = await this.putNoteContent(card_id, title, content, tags, complexity);
        //const notes = await this.getNote(card_id);
        const notes = await this.getNote(response.card_id);
        updated_note = notes[0];
    } catch (e) {
      alert(e);
      //console.log(e);
      return;
    } 
    updated_notes[edited_card_index] = updated_note;
    this.setState({
      notes: updated_notes,
      show_card_index: edited_card_index,
      editedCard: {},
      isEditMode: false,
      isSaveMode: false,
      isFlipped: true,
      linkMode: 0,
      allTags: this.state.allTags.concat(this.state.editedCard.tags.filter(t => (!this.state.allTags.includes(t)))).sort(),
    });
  }
  
  onArchiveClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const card_id = this.state.notes[this.state.show_card_index].card_id;
    const archive_flag = this.state.notes[this.state.show_card_index].archive_flag;
    var updated_note = {};
    const edited_card_index = this.state.show_card_index;
    try {
        await this.putArchiveNote(card_id, (archive_flag ? "restore" : "archive"));
        const notes = await this.getNote(card_id);
        updated_note = notes[0];
    } catch (e) {
      console.log(e);
    } 
    const updated_notes = this.state.notes;
    updated_notes[edited_card_index] = updated_note;
    this.setState({
      notes: updated_notes,
      isEditMode: false,
      isSaveMode: false,
      isFlipped: true,
    });
  }
  /*
  onCancelClick = async (event, i) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isSaveMode: false,
    });
  }
  */
  onAddClick = async (event) => {
    //console.log("onAddClick")
    this.setState({
      isEditMode: true,
      isListMode: false,
      editedCard: {
        card_id: '0',
        title: '',
        content: '',
        complexity: 0.0,
        complexityLock: false,
        tags: [],
      }
    });
  }
  
  onFlipClick() {
    this.setState({
      isFlipped: !this.state.isFlipped,
    });
  }
  
  onEditClick() {
    this.setState({
      isEditMode: true,
      linkMode: 0,
      editedCard: {
        card_id: this.state.notes[this.state.show_card_index].card_id,
        title: this.state.notes[this.state.show_card_index].title.trim().split("\n")[0],
        content: this.state.notes[this.state.show_card_index].content,
        complexity: this.state.notes[this.state.show_card_index].complexity*100,
        complexityLock: Math.abs(this.state.notes[this.state.show_card_index].complexity-this.state.notes[this.state.show_card_index].content.length/2056.0)<0.01 ? false : true, // calculated complexity is different from the recorded, i.e. it is overriden
        tags: this.state.notes[this.state.show_card_index].tags ? this.state.notes[this.state.show_card_index].tags.split(';') : [],
      }
    });
  }
  
  onTextSelection(event) {
    //event.preventDefault();
    //event.stopPropagation();
    //console.log("onTextSelection", event)
    const ta = this.refs.ta; 
    if((ta.selectionEnd - ta.selectionStart) < 2) {
      this.setState({linkMode: 0});
    } else {
      const v = this.state.editedCard.content;
      const selectedText =  v.substr(ta.selectionStart, ta.selectionEnd - ta.selectionStart);
      let linkMode = 1;
      if(ta.selectionStart > 1 && v.substr(ta.selectionStart-2, 2)==="[[" && v.substr(ta.selectionEnd, 2)==="]]") {
        if (selectedText.substr(0, 6) === 'cloze:') {
          linkMode = linkMode * 3;
        } else if (selectedText.substr(0, 8) === 'encrypt:') {
          linkMode = linkMode * 5;
        } else {
          linkMode = linkMode * 2;
        }
      }
      if(ta.selectionStart > 1 && v.substr(ta.selectionStart-2, 2)==="((" && v.substr(ta.selectionEnd, 2)==="))") {
        linkMode = linkMode * 3;
      }
      if(ta.selectionStart > 1 && v.substr(ta.selectionStart-2, 2)==="{{" && v.substr(ta.selectionEnd, 2)==="}}") {
        linkMode = linkMode * 5;
      }
      this.setState({linkMode: linkMode});
    }
  }
  
  encryptText(value) {
    return CryptoJS.AES.encrypt(value, "Secret Passphrase").toString();
    //return "encrypt|"+CryptoJS.AES.encrypt(value, "Secret Passphrase").toString();
    //return 'ENC' + value;
  }
  
  decryptText(value) {
    return (v => v ? v : "Decryption Failed")(CryptoJS.AES.decrypt(value.replace(/^(encrypt\|)?/, ''), "Secret Passphrase").toString(CryptoJS.enc.Utf8));
    //return value.slice(3);
  }
  
  onToggleLink(type='link') {
    //console.log("onToggleLink")
    
    const ta = this.refs.ta; 
    const openBracket = "[["; //type === "link" ? "[[" : ( type === "cloze" ? "((" : "{{" );
    const endBracket = "]]"; //type === "link" ? "]]" : ( type === "cloze" ? "))" : "}}" );
    if((ta.selectionEnd - ta.selectionStart) < 2) {
      return;
    }
    const v = this.state.editedCard.content;
    const editedCard = this.state.editedCard;
    const selectedText =  v.substr(ta.selectionStart, ta.selectionEnd - ta.selectionStart);
    if(ta.selectionStart > 1 && v.substr(ta.selectionStart-2, 2)===openBracket && v.substr(ta.selectionEnd, 2)===endBracket) {
      // remove the brackets
      if (selectedText.substr(0, 6) === 'cloze:') {
        editedCard.content =  v.substr(0, ta.selectionStart-2) + selectedText.substr(6) + v.substr(ta.selectionEnd+2);
      } else if (selectedText.substr(0, 8) === 'encrypt:') {
        editedCard.content =  v.substr(0, ta.selectionStart-2) + this.decryptText(selectedText.substr(8)) + v.substr(ta.selectionEnd+2);
      } else {
        editedCard.content =  v.substr(0, ta.selectionStart-2) + selectedText + v.substr(ta.selectionEnd+2);
        //editedCard.content =  v.substr(0, ta.selectionStart-2) + (type === 'encrypt' ? this.decryptText(selectedText) : selectedText) + v.substr(ta.selectionEnd+2);
      }
      //editedCard.content =  v.substr(0, ta.selectionStart-2) + v.substr(ta.selectionStart, ta.selectionEnd - ta.selectionStart) + v.substr(ta.selectionEnd+2);
    } else {
      // add the brackets
      // editedCard.content = v.substr(0, ta.selectionStart) + openBracket + v.substr(ta.selectionStart, ta.selectionEnd - ta.selectionStart) + endBracket + v.substr(ta.selectionEnd);
      if (type === 'cloze') {
        editedCard.content = v.substr(0, ta.selectionStart) + openBracket + "cloze:" + selectedText + endBracket + v.substr(ta.selectionEnd);
      } else if (type === 'encrypt') {
        editedCard.content = v.substr(0, ta.selectionStart) + openBracket + 'encrypt:' + this.encryptText(selectedText) + endBracket + v.substr(ta.selectionEnd);
      } else {
        editedCard.content = v.substr(0, ta.selectionStart) + openBracket + selectedText + endBracket + v.substr(ta.selectionEnd);
      }
      //editedCard.content = v.substr(0, ta.selectionStart) + openBracket + (type === 'encrypt' ? this.encryptText(selectedText) : selectedText) + endBracket + v.substr(ta.selectionEnd);
    }
    this.setState({isSaveMode: true, linkMode: 0, editedCard: editedCard});
  }
  
  renderNotesList(notes) {
    //this.state.isPrevDisabled = this.state.show_card_index > 0 ? false : true;
    //this.state.isListEnabled = notes.length > 1 ? true : false;
    const TagDropdown = React.forwardRef(({children, onClick}, ref) =>
    <Badge pill variant="dark" style={{fontSize: "12px"}} ref={ref} onClick={(e) => {e.stopPropagation(); e.preventDefault(); onClick(e) }}>
      {children}
    </Badge> );
    
    let addTagInput = React.createRef();
    
    const selectTag = (k, e) => {
      if (!k) {
        return;
      }
      const selectedTags= this.state.isEditMode ? this.state.editedCard.tags : this.state.selectedTags;
      if (!selectedTags.includes(k)) {
        selectedTags.push(k);
      }
      if(this.state.isEditMode) {
        const editedCard = this.state.editedCard;
        editedCard.tags = selectedTags;
        this.setState({isSaveMode: true, enteredTag: undefined, editedCard: editedCard});
      } else {
        this.setState({selectedTags: selectedTags, enteredTag: undefined}, ()=>this.onHomeClick(e));
        localStorage.setItem('selectedTags', JSON.stringify(selectedTags));
      }
    };
    
    const removeTag = (k, e) => {
      if (!k) {
        return;
      }
      const selectedTags= this.state.isEditMode ? this.state.editedCard.tags : this.state.selectedTags;
      const i = selectedTags.indexOf(k);
      if (i > -1) {
        selectedTags.splice(i, 1);
      }
      if(this.state.isEditMode) {
        const editedCard = this.state.editedCard;
        editedCard.tags = selectedTags;
        this.setState({isSaveMode: true, editedCard: editedCard});
       } else {
         this.setState({selectedTags: selectedTags}, ()=>this.onHomeClick(e));
        localStorage.setItem('selectedTags', JSON.stringify(selectedTags));
       }
    };
    
    const dateToString = ((value, showYear=true) => {
      const options = {month: '2-digit', day: '2-digit', timeZone: 'UTC'};
      if (showYear) {
        options.year = '2-digit';
      }
      const dateValue = new Date(value);
      dateValue.setHours(0, 0, 0, 0);
      return dateValue.valueOf() === todayDate.valueOf() ? "Today" : (
        dateValue.valueOf() === (todayDate.valueOf() - 86400000) ? "Yesterday" : (
          dateValue.valueOf() === (todayDate.valueOf() + 86400000) ? "Tomorrow" : (
            dateValue.toLocaleDateString('en', options)
          )
        )
      );
    });
    
    const isOverdue = (value => {
      const dateValue = new Date(value);
      dateValue.setHours(0, 0, 0, 0);
      return dateValue.valueOf() < todayDate.valueOf();
    });
    
    return (
      <Container>
      <Row style={{minHeight: "70vh"}} className="justify-content-center h-100">
      <Col md="4" style={{padding: "0"}}>
      <Card className="h-100 border-0">
        <Card.Header className="w-100 pl-2 pr-2">
          <Nav defaultActiveKey="#" fill justify>
            <Nav.Item><Nav.Link onClick={this.onPrevClick.bind(this)} disabled={this.state.isEditMode || this.state.isPrevDisabled || this.state.isSearchMode} href="#">
              <FontAwesomeIcon icon={faChevronLeft} size="lg"/>
            </Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link onClick={(e) => this.onHomeClick(e, true)} disabled={this.state.isEditMode || this.state.isSearchMode} href="#">
              <FontAwesomeIcon icon={faHome} size="lg"/>
            </Nav.Link></Nav.Item>
            {this.state.isListMode ?
            <Nav.Item><Nav.Link onClick={() => this.setState({isListMode: !this.state.isListMode})} disabled={this.state.isEditMode || this.state.isSearchMode} href="#">
              <FontAwesomeIcon icon={faImage} size="lg"/>
            </Nav.Link></Nav.Item>
            :
            <Nav.Item><Nav.Link onClick={() => this.setState({isListMode: !this.state.isListMode})} disabled={this.state.isEditMode || this.state.isSearchMode} href="#">
              <FontAwesomeIcon icon={faList} size="lg"/>
            </Nav.Link></Nav.Item>
            }
            <Nav.Item><Nav.Link onClick={() => this.setState({isSearchMode: true})} disabled={this.state.isEditMode || this.state.isSearchMode} href="#">
              <FontAwesomeIcon icon={faSearch} size="lg"/>
            </Nav.Link></Nav.Item>
          </Nav>
          <div className="d-flex justify-content-between align-items-center">
          <div className="align-items-center text-center d-inline-flex">
            <Dropdown ref={addTagInput}
                onSelect={(k, e) => {selectTag(k, e)}}
                onClick={(e) => {if(this.state.enteredTag) selectTag(this.state.enteredTag, e)}}
            >
            {
            this.state.isListMode || this.state.isEditMode ?
            <span>
              <Dropdown.Toggle as={TagDropdown} key="right" drop="right">{this.state.isListMode ? "Filter" : "Add Tag"}</Dropdown.Toggle>
              <Dropdown.Menu style={{height: "400px", overflowY: "scroll", padding: "0"}}>
               {this.state.isEditMode ?
                <Dropdown.Item eventKey={this.state.enteredTag}>
                  <Form.Control autoFocus name="tagName" type="text" placeholder="Enter Tag"
                    value={this.state.enteredTag}
                    onKeyDown={(e) => {if(e.key==='Enter') {addTagInput.current.click()}}}
                    onChange={(e) => {e.stopPropagation();this.setState({enteredTag: e.target.value})}}
                    onClick={(e) => {e.stopPropagation()}}/>
                </Dropdown.Item>
                : null
               }
               { 
                 this.state.allTags.map(t => <Dropdown.Item eventKey={t}>{t}</Dropdown.Item>)
               }
               {
                 this.state.isListMode ?
                 <Dropdown.Divider/>
                 : null
               }
               {
                 this.state.isListMode ?
                 <Dropdown.Item onClick={(e) => {this.setState({isShowArchived: !this.state.isShowArchived}, ()=>this.onHomeClick(e))}}>{this.state.isShowArchived ? "Hide Archived" : "Show Archived"}</Dropdown.Item>
                 : null
               }
              </Dropdown.Menu>
             </span>
             : null
            }
            </Dropdown>
            {
              this.state.isEditMode ?
                this.state.editedCard.tags.map(t => <span>&nbsp;<Badge pill variant="primary" style={{fontSize: "12px" }} onClick={(e) => removeTag(t, e)}>{t}</Badge></span>)
              : (
                this.state.isListMode ?
                  this.state.selectedTags.map(t => <span>&nbsp;<Badge pill variant="primary" style={{fontSize: "12px" }} onClick={(e) => removeTag(t, e)}>{t}</Badge></span>)
                :
                  (notes[this.state.show_card_index].tags ? notes[this.state.show_card_index].tags.split(';') : []).map((t, i) => <span key={i}>&nbsp;<Badge key={i} pill variant="secondary" style={{fontSize: "12px" }}>{t}</Badge></span>)
                )
            }
          </div>
            <span>
            {this.state.isShowArchived ? <FontAwesomeIcon onClick={(e) => {this.setState({isShowArchived: false}, ()=>this.onHomeClick(e))}} icon={faTrash} size="sm"/> : null}
            </span>
          </div>
        </Card.Header>
        {/*
        <Card.Body style={{height: "400px", overflowY: "scroll", padding: "0"}}>
        */}
        <Card.Body style={{height: "400px", padding: "0", overflowY: this.state.isListMode ? "scroll" : "unset"}}>
        {this.state.isSearchMode ?
          <Card key="search" className="border-0 h-100">
            <Card.Body className="align-items-center">
              <Form>
                <Form.Group>
                  <Form.Control name="searchText" type="text" placeholder="Search Text"
                    value={this.state.searchText}
                    onKeyDown={(e) => {if(e.key==='Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        if(this.state.searchText && this.state.searchText.length > 1) {
                          this.setState({isSearchMode: false});
                          this.onLinkClick(e, this.state.searchText);
                        }
                      }
                    }}
                    onChange={(e) => {this.setState({searchText: e.target.value})}}/>
                </Form.Group>
              <Container className="text-center">
                <Button variant="secondary" size="sm" disabled={!this.state.searchText || this.state.searchText.length < 2} onClick={(e) => {this.setState({isSearchMode: false});this.onLinkClick(e, this.state.searchText)}}>Search</Button>
                &nbsp;
                <Button variant="secondary" size="sm" disabled={false} onClick={() => this.setState({isSearchMode: false})}>Cancel</Button>
              </Container>
              </Form>
            </Card.Body>
          </Card>
          :
          (this.state.isListMode ?
            <ListGroup>
              {notes.map((n, i) => <ListGroup.Item className="border-left-0 border-right-0 border-bottom-0 border-top-1"
              onClick={() => this.onItemClick(i)} as="li">{
                <div className="w-100 d-flex justify-content-between align-items-center">
                <span>
                {n.title ? n.title.trim().split("\n")[0] : 'Unknown Item'}
                </span>
                <span>
                {n.tags ? n.tags.split(';').map(t => <span><Badge variant="secondary" pill style={{fontSize: "11px", margin: "0"}}>{t}</Badge>&nbsp;</span>) : null}
                &nbsp;
                {n.archive_flag ? <FontAwesomeIcon color="grey" icon={faTrash} size="sm"/> : null}
                </span>
                </div>
              }
              </ListGroup.Item>  )}
            </ListGroup>
            :
            (this.state.isEditMode ?
              <Card key="editor" className="border-0">
                <Card.Body>
                  <Form>
                    <Form.Group controlId="editCardTitle">
                      <Form.Control name="cardTitle" type="text" placeholder="Enter Card Title"
                        value={this.state.editedCard.title} 
                        onChange={(e) => {const editedCard=this.state.editedCard;editedCard.title=e.target.value;this.setState({isSaveMode: true, editedCard: editedCard})}}/>
                    </Form.Group>
                    <Form.Group controlId="editCardBody">
                      <Form.Control ref="ta" name="cardBody" as="textarea" rows={10}
                      value={this.state.editedCard.content}
                      onChange={(e) => {const editedCard=this.state.editedCard;editedCard.content=e.target.value;editedCard.complexity=editedCard.complexityLock?editedCard.complexity:100.0*e.target.value.length/2056.0;this.setState({isSaveMode: true, editedCard: editedCard})}}
                      onSelect={(e) => {this.onTextSelection(e)}}
                      onDoubleClick={(e) => {this.onTextSelection(e)}}
                      />
                    </Form.Group>
                    {this.state.linkMode ?
                      <div className="w-100 align-items-center text-center pl-0 pr-0">
                         <Badge variant="dark" style={{fontSize: "12px"}} onClick={() => this.onToggleLink('link')}>{this.state.linkMode % 2 ? "Link" : "Unlink"}</Badge>
                         &nbsp;
                         <Badge variant="dark" style={{fontSize: "12px"}} onClick={() => this.onToggleLink('cloze')}>{this.state.linkMode % 3 ? "Cloze" : "Uncloze"}</Badge>
                         &nbsp;
                         <Badge variant="dark" style={{fontSize: "12px"}} onClick={() => this.onToggleLink('encrypt')}>{this.state.linkMode % 5 ? "Encrypt" : "Decrypt"}</Badge>
                      </div>
                     :
                      <Form.Group controlId="editComplexity">
                      <div className="w-100 align-items-center text-center p-0 m-0">
                        <Form.Control type="range" size="sm" value={this.state.editedCard.complexity} onChange={e => {
                        const editedCard = this.state.editedCard; editedCard.complexity=e.target.value; this.setState({isSaveMode: true, editedCard: editedCard})}}/>
                      {this.state.editedCard.complexityLock ?
                         <Badge variant="dark" style={{fontSize: "12px"}} onClick={() => {const editedCard=this.state.editedCard;editedCard.complexityLock=false;this.setState({editedCard: editedCard})}}>Unlock</Badge>
                      :null}
                      </div>
                      </Form.Group>
                    }
                  </Form>
                </Card.Body>
              </Card>
              :
              <Card key="viewer" className="w-100 h-100 d-inline-flex"><Card.Body className="w-100 h-100 align-items-center overflow-auto p-0">
              {/*<ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">*/}
                {!this.state.isFlipped ?
                /*<Card key="front" className="react-card-flip-front border-0" onClick={this.onFlipClick.bind(this)}>*/
                <Card key="front" className="h-100 w-100 align-items-center d-inline-flex border-0" onClick={this.onFlipClick.bind(this)}>
                  <Card.Body className="h-100 align-items-center d-inline-flex">
                    <Card.Title className="text-center">{
                      // if the card consists of an image or audio link only, flip it
                      /^(#!md[^!]?)?(!\[[^\]]*\]\([^\)]*\)|\[\[[^\]]*type=audio[^\]]*\]\])$/.test(notes[this.state.show_card_index].content.trim()) ?
                      <MDViewer id={this.state.show_card_index} content={notes[this.state.show_card_index].content} onLinkClick={this.onLinkClick} decryptText={this.decryptText}/>
                      : notes[this.state.show_card_index].title.trim().split("\n")[0]
                    }</Card.Title>
                  </Card.Body>
                </Card>
                :
                /*<Card key="back" className="react-card-flip-back border-0" onClick={this.onFlipClick.bind(this)}>*/
                <Card key="back" className="h-100 w-100 align-items-center d-inline-flex border-0" onClick={this.onFlipClick.bind(this)}>
                  <Card.Body className={`h-100 w-100 justify-content-center d-flex w-100 overflow-auto ${notes[this.state.show_card_index].content.split(/[\n\r]/).length > linesForTopAdjust ? "align-items-top" : "align-items-center"}`} style={{overflowZ: "scroll", WebkitOverflowScrolling: "touch"}}>
                    <Card.Text className={/^\[\[([^\]]*)\]\]$/.test(notes[this.state.show_card_index].content) ? "text-center" : "text-justify"}>
                      <p style={{fontFamily: "inherit"}} className="h5 text-center">{notes[this.state.show_card_index].title.trim().split("\n")[0]}</p>
                      <MDViewer id={this.state.show_card_index} content={notes[this.state.show_card_index].content} onLinkClick={this.onLinkClick} decryptText={this.decryptText}/>
                    </Card.Text>
                  </Card.Body>
                </Card>
                }
              {/*</ReactCardFlip>*/}
              </Card.Body></Card>
            )
          )
        }
        </Card.Body>
        <Card.Footer className="w-100 align-items-center text-center pl-0 pr-0">
            {this.state.isEditMode ?
            <Nav defaultActiveKey="#" fill justify>
              <Nav.Item><Nav.Link onClick={(e) => this.onSaveClick(e, 0)} disabled={!this.state.isSaveMode} href="#">
                <FontAwesomeIcon icon={faSave} size="lg"/>
              </Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link onClick={(e) => this.onEditClick(e)} disabled={!this.state.isSaveMode} href="#">
                <FontAwesomeIcon icon={faUndo} size="lg"/>
              </Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link onClick={(e) => this.onArchiveClick(e)} disabled={this.state.isSaveMode} href="#">
                <FontAwesomeIcon icon={notes[this.state.show_card_index].archive_flag ? faTrashRestore : faTrash} size="lg"/>
              </Nav.Link></Nav.Item>
              {this.state.isSaveMode ?
                <Nav.Item><Nav.Link onClick={(e) => this.setState({isEditMode: false, isSaveMode: false, linkMode: 0})} disabled={!this.state.isSaveMode} href="#">
                  <FontAwesomeIcon icon={faTimesCircle} size="lg"/>
                </Nav.Link></Nav.Item>
                :
                <Nav.Item><Nav.Link onClick={() => this.setState({isEditMode: false, linkMode: 0})} disabled={!this.state.isEditEnabled || this.state.isSaveMode} href="#">
                  <FontAwesomeIcon icon={faEye} size="lg"/>
                </Nav.Link></Nav.Item>
              }
            </Nav>
            :
            <Nav defaultActiveKey="#" fill justify>
              {this.state.isListMode ?
                <Nav.Item><Nav.Link onClick={this.onAddClick.bind(this)} disabled={!this.state.isEditEnabled} href="#">
                  <FontAwesomeIcon icon={faPlus} size="lg"/>
                </Nav.Link></Nav.Item>
              :
                <Nav.Item><Nav.Link onClick={this.onEditClick.bind(this)} disabled={!this.state.isEditEnabled || this.state.isListMode || this.state.isSearchMode} href="#">
                  <FontAwesomeIcon icon={faPencilAlt} size="lg"/>
                </Nav.Link></Nav.Item>
              }
              <Nav.Item><Nav.Link onClick={(e) => this.onFeedbackClick(e, 0)} disabled={this.state.isListMode || this.state.isSearchMode} href="#">
                <FontAwesomeIcon icon={faThumbsDown} size="lg"/>
              </Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link onClick={(e) => this.onFeedbackClick(e, 1)} disabled={this.state.isListMode || this.state.isSearchMode} href="#">
                <FontAwesomeIcon icon={faThumbsUp} size="lg"/>
              </Nav.Link></Nav.Item>
              {/*
              <Nav.Item><Nav.Link onClick={(e) => this.onFeedbackClick(e, 2)} disabled={this.state.isListMode} href="#">
                <span>
                <FontAwesomeIcon icon={faThumbsUp} size="lg"/>
                <FontAwesomeIcon icon={faThumbsUp} size="sm"/>
                </span>
              </Nav.Link></Nav.Item>
              */}
              <Nav.Item><Nav.Link onClick={this.onNextClick.bind(this)} disabled={this.state.isNextDisabled || this.state.isListMode || this.state.isSearchMode} href="#">
                <FontAwesomeIcon icon={faChevronRight} size="lg"/>
              </Nav.Link></Nav.Item>
            </Nav>
            }
        {this.state.isListMode ?
          <div>
            <Badge style={{fontSize: "14px", color: "grey"}} variant="light">Cards<br/>{notes.length}</Badge>
          </div>
          :
          <div>
            <Badge style={{fontSize: "14px", color: "grey"}} variant="light">Card<br/><span style={{fontSize: "12px"}}>{(this.state.show_card_index+1) + "/" + notes.length}</span></Badge>
            &nbsp;
            <Badge style={{fontSize: "14px", color: "grey"}} variant="light">Score<br/><span style={{fontSize: "12px"}}>
              {(notes[this.state.show_card_index].confirmed_count ? notes[this.state.show_card_index].confirmed_count : "0") +
              ":" + (notes[this.state.show_card_index].viewed_count ? notes[this.state.show_card_index].viewed_count : "0") }</span>
            </Badge>
            {notes[this.state.show_card_index].created_date ?
            <span>
            &nbsp;
            <Badge style={{fontSize: "14px", color: "grey"}} variant="light">Created<br/><span style={{fontSize: "12px"}}>{
              //new Date(notes[this.state.show_card_index].created_date).toLocaleDateString('en', {year: '2-digit', month: '2-digit', day: '2-digit', timeZone: 'UTC'})
              dateToString(notes[this.state.show_card_index].created_date)
            }</span></Badge>
            </span>
            : null}
            {notes[this.state.show_card_index].viewed_date ?
            <span>
            &nbsp;
            <Badge style={{fontSize: "14px", color: "grey"}} variant="light">Viewed<br/><span style={{fontSize: "12px"}}>{
            //new Date(notes[this.state.show_card_index].viewed_date).toLocaleDateString('en', {month: '2-digit', day: '2-digit', timeZone: 'UTC'})
              dateToString(notes[this.state.show_card_index].viewed_date, false)
            }</span></Badge>
            </span>
            : null}
            {notes[this.state.show_card_index].next_date ?
            <span>
            &nbsp;
            <Badge style={{fontSize: "14px", color: isOverdue(notes[this.state.show_card_index].next_date) ? "red" : "grey"}} variant="light">Next<br/><span style={{fontSize: "12px"}}>{
              //new Date(notes[this.state.show_card_index].next_date).toLocaleDateString('en', {month: '2-digit', day: '2-digit', timeZone: 'UTC'})
              dateToString(notes[this.state.show_card_index].next_date, false)
            }</span></Badge>
            </span>
            : null}
          </div>
        }
        </Card.Footer>
      </Card>
      </Col>
      </Row>
      </Container>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Memonote</h1>
        <p>A simple memorizing app</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className="notes">
        <Card className="rounded-0">
          <Card.Body style={{padding: "0"}}>
            {
              !this.state.isLoading && this.state.notes.length && this.renderNotesList(this.state.notes)
            }
          </Card.Body>
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}
