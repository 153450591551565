import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import config from "./config";


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    //identityPoolId: config.cognito.IDENTITY_POOL_ID, // shouldn't be needed if userPoolId is provided
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  /* I don't think this is in use
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  */
  API: {
    endpoints: [
      {
        name: "memonote",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => { 
          // return { Authorization: 'xxxx' }
          // return { Authorization : 'token' };
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      },
    ]
  }
});

ReactDOM.render(
  <Router>
    <App version={config.app.version} />
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();