export default {
  // MAX_ATTACHMENT_SIZE: 5000000,
  /* I dont think this is in use - must be a leftover... the bucket is empty
  s3: {
    REGION: process.env.REACT_APP_REGION, //"us-east-2",
    BUCKET: "memonote-media"
  },
  */
  app: {
    //version: new Date().toISOString().slice(0, 10)+'-'+process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION+'-'+process.env.REACT_APP_ENV,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION, //"us-east-2",
    URL: process.env.REACT_APP_API_BASE_URL // "https://0gs80ohk1c.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION, //"us-east-2",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID, //"us-east-2_sjjDpUJkA",
    APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID, //"3f0equchrjnr99a93fb7d81sn",
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID // "us-east-2:52b379a0-9ba1-4b36-bb0f-27d7f9bbd607"
  }
};
