import React, { useState, useEffect} from "react";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import math from 'remark-math';
//import katex from 'rehype-katex';
import {InlineMath, BlockMath} from 'react-katex'
import {render} from 'react-dom';
import majorCodec from '../components/MajorCodec';
import Mermaid from "react-mermaid2";
import "./MDViewer.css";
import 'katex/dist/katex.min.css'; 
//var visit = require('unist-util-visit');
//var myplugin = require('./myplugin');
//var breaks = require('./myremark-breaks')
//var breaks = require('./remark-breaks');
//const markdown = require('remark-parse')
const fetch = require('node-fetch');
const { wikiLinkPlugin } = require('remark-wiki-link');

/*
function isString(s) {
  return (typeof s === 'string' || s instanceof String);
}
*/

function getOptions(s) {
  return s ? s.trim().split(';').reduce((p, c, i) => {
    let k, v;
    [k, v] = (c+'=').split('=');
    p[k] = v.length ? v : true;
    return p;
  }, {}) : {};
}

const WikipediaViewer = (props) => {
  const [content, setContent] = useState(undefined);
  
  const getWikipediaSummary = async (page) => {
    const r = await fetch('https://en.wikipedia.org/api/rest_v1/page/summary/'+page);
    const j = await r.json();
    setContent(j.extract);
  };
  
  useEffect(() => {
    getWikipediaSummary(props.page);
  });
  
  return <div className="text-justify">{content}</div>;
};

const EncodeViewer = (props) => {
  const [content, setContent] = useState(undefined);
  const [value, setValue] = useState(undefined);
  const [revealed, setRevealed] = useState(false);
  
  useEffect(() => {
    //console.log('encoder value', props.value)
    const text = props.options.encrypted ? props.decryptText(props.value) : props.value;
    setValue(text);
    setContent(
      //(props.value.length === 1 && isString(props.value[0])) ? 
      props.value ?
        //(props.options && props.options.codec === 'major' ? majorCodec(props.value[0]) : 'unknown codec'+props.value[0])
        (props.options && props.options.codec === 'major' ? majorCodec(text) : 'unknown codec'+text)
        :
        'this value cannot be encoded'
    );
  });
  return ((v) => {
    return((props.options && props.options.clozed) ? <ClozeViewer value={v} options={props.options}/> : v);
  })(
  <span style={{backgroundColor: "#f9ffdd"}} onClick={e => {setRevealed(!revealed);e.stopPropagation()}}>{revealed ? value : content}</span>
  );
  ///return <span style={{backgroundColor: "#f9ffdd"}} onClick={e => {setRevealed(!revealed);e.stopPropagation()}}>{revealed ? value : content}</span>;
};

const ClozeViewer = (props) => {
  //const [content, setContent] = useState(undefined);
  const [value, setValue] = useState(undefined);
  const [label, setLabel] = useState(undefined);
  const [revealed, setRevealed] = useState(false);
  useEffect(() => {
    setValue(props.value);
    setLabel((props.options && props.options.label) ? props.options.label : ". . . . .");
  });
  return <span>{(revealed ? value
       : <span onClick={e => {setRevealed(!revealed);e.stopPropagation()}} style={{backgroundColor: "#f9ffdd"}}>{label}</span>)}</span>;
};

/*
render(
  <ReactMarkdown
    plugins={[footnotes]}
    renderers={renderers}
    children={`place some footnotes here`}
  />,
  document.body
)
// This throws, because the footnotes can’t be handled.
From there, you can add a renderer:
*/

const MDViewer = (props) => {
  // see https://github.com/landakram/remark-wiki-link
  const [revealed, setReveal] = useState({});
  
  useEffect(() => {
    setReveal({});
  }, []);  
  
  const wrapper = {
    'center':  (v) => `<div class="text-center">${v}</div>`,
  };

  
  const renderers = {
    inlineMath: ({value}) => <InlineMath math={value} />,
    math: ({value}) => <BlockMath math={value} />,
    image: function ({src, alt, node}) {
      //console.log(arguments)
      //console.log('src', src, 'alt', alt, 'node', node);
      var label, options;
      [label, options] = alt ? (alt+":").split(":") : [undefined, undefined];
      options = getOptions(options ? options : '');
      return ((v) => {
        return options.center ? <div class="text-center">{v}</div> : v;
      })(<img alt={label} src={src} width={options.width ? options.width : "100%"} height={options.height ? options.height : "100%"}/>);
    },
    wikiLink: function ({value, data, node}) {
      //console.log(arguments)
      // [[link:text:options]]
      const link = value;
      const key = node.position && node.position.start ? node.position.start.offset : 0;
      var text = data.alias;
      var options;
      [text, options] = text ? (text+":").split(":") : [undefined, undefined];
      options = getOptions(options ? options : '');
      //console.log('link', link)
      //console.log('data', data, 'text', text, 'options', options)
      //console.log('node', node, 'key', key)
      if (link === 'wiki' && text) {
        return <WikipediaViewer page={text}/>;
      } else if ((link === 'https' || link === 'http') && text && ["audio/mpeg", "audio/ogg"].includes(options.type)) {
         return <audio controls onClick={e => e.stopPropagation()}> <source src={link+":"+text} type={options.type}/>"your browser does not support audio playback"</audio>;
      } else if (link === 'cloze' && text) {
        return <ClozeViewer value={text} options={options}/>;
      } else if (link === 'encode' && text) {
        return <EncodeViewer value={text} options={options} decryptText={props.decryptText}/>;
      } else if (link === 'encrypt' && text) {
        return <span>{props.decryptText(text)}</span>;
      } else {
        return ((v) => {
          return (options.clozed ? <ClozeViewer value={v} options={options}/> : v);
        })(<a key={key} href="#" onClick={(e) => props.onLinkClick(e, link)}>{text ? text : link}</a>);
      }
    }
  };
  return (
    props.content.substr(0, 4) === "#!mm" ?
      <Mermaid chart={props.content.substr(4)}/>
    :
      <ReactMarkdown
        className="MDViewer"
        plugins={[wikiLinkPlugin, gfm, math]}
        renderers={renderers}
        children={props.content.replace(/^#!md/, "")}/>
    );
};
export default MDViewer;
