import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import { Auth } from "aws-amplify";
import "./App.css";
import Routes from "./Routes";

class App extends Component {
  
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      encryptionKey: undefined,
    };
  }
  
  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  
    this.setState({
      isAuthenticating: false,
    });
  }
  
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }
  
  handleLogout = async event => {
    await Auth.signOut();
    
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }
  
  render() {
    
    const version = this.props.version; //"2021.04.11";
    
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };
  
    return (
      !this.state.isAuthenticating &&
      <div className="App container">
      <Card className="border-0">
        {/*<Navbar fluid collapseOnSelect>*/}
        <Navbar bg="white" expand={true}>
            <Navbar.Brand href="/">Memonote</Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              {this.state.isAuthenticated
                ? <Nav className="justify-content-end">
                    <Nav.Item onClick={this.handleLogout}>
                      <Nav.Link href="#">Logout</Nav.Link>
                    </Nav.Item>
                  </Nav>
                : <Nav className="justify-content-end">
                    <Nav.Item>
                      <Nav.Link href="/signup">Signup</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="/login">Login</Nav.Link>
                    </Nav.Item>
                  </Nav>
              }
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
        <div className="text-center small">{version}</div>
      </Card>
      </div>
    );
  }
}

export default withRouter(App);
