import React  from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faDog, faCat} from '@fortawesome/free-solid-svg-icons';
//import getMajorIcon from './MajorIcons';
const numberMap = {
  '0': {code: 'sea', icon: undefined},
  '1': {code: 'tea', icon: undefined},
  '2': {code: 'knee', icon: undefined},
  '3': {code: 'ham', icon: undefined},
  '4': {code: 'ear', icon: undefined},
  '5': {code: 'owl', icon: undefined},
  '6': {code: 'shoe', icon: undefined},
  '7': {code: 'key', icon: undefined},
  '8': {code: 'hoof', icon: undefined},
  '9': {code: 'bee', icon: undefined},
  '00': {code: 'sauce', icon: undefined},
  '01': {code: 'soda', icon: undefined},
  '02': {code: 'swan', icon: undefined},
  '03': {code: 'sumo', icon: undefined},
  '04': {code: 'sir', icon: undefined},
  '05': {code: 'sail', icon: undefined},
  '06': {code: 'siege', icon: undefined},
  '07': {code: 'sock', icon: undefined},
  '08': {code: 'safe', icon: undefined},
  '09': {code: 'soap', icon: undefined},
  '10': {code: 'dice', icon: undefined},
  '11': {code: 'tutu', icon: undefined},
  '12': {code: 'tuna', icon: undefined},
  '13': {code: 'time', icon: undefined},
  '14': {code: 'deer', icon: undefined},
  '15': {code: 'tile', icon: undefined},
  '16': {code: 'dj', icon: undefined},
  '17': {code: 'dog', icon: <FontAwesomeIcon icon={faDog} size="sm"/>},
  '18': {code: 'dove', icon: undefined},
  '19': {code: 'tape', icon: undefined},
  '20': {code: 'nose', icon: undefined},
  '21': {code: 'net', icon: undefined},
  '22': {code: 'noon', icon: undefined},
  '23': {code: 'gnome', icon: undefined},
  '24': {code: 'honor', icon: undefined},
  '25': {code: 'nail', icon: undefined},
  '26': {code: 'niche', icon: undefined},
  '27': {code: 'neck', icon: undefined},
  '28': {code: 'knife', icon: undefined},
  '29': {code: 'knob', icon: undefined},
  '30': {code: 'mouse', icon: undefined},
  '31': {code: 'mat', icon: undefined},
  '32': {code: 'money', icon: undefined},
  '33': {code: 'mom', icon: undefined},
  '34': {code: 'mayor', icon: undefined},
  '35': {code: 'mail', icon: undefined},
  '36': {code: 'amish', icon: undefined},
  '37': {code: 'mike', icon: undefined},
  '38': {code: 'movie', icon: undefined},
  '39': {code: 'map', icon: undefined},
  '40': {code: 'rose', icon: undefined},
  '41': {code: 'rat', icon: undefined},
  '42': {code: 'rain', icon: undefined},
  '43': {code: 'rum', icon: undefined},
  '44': {code: 'arrow', icon: undefined},
  '45': {code: 'rail', icon: undefined},
  '46': {code: 'roach', icon: undefined},
  '47': {code: 'rock', icon: undefined},
  '48': {code: 'roof', icon: undefined},
  '49': {code: 'rope', icon: undefined},
  '50': {code: 'lace', icon: undefined},
  '51': {code: 'lid', icon: undefined},
  '52': {code: 'lion', icon: undefined},
  '53': {code: 'llama', icon: undefined},
  '54': {code: 'lawyer', icon: undefined},
  '55': {code: 'lily', icon: undefined},
  '56': {code: 'leash', icon: undefined},
  '57': {code: 'lock', icon: undefined},
  '58': {code: 'leaf', icon: undefined},
  '59': {code: 'lab', icon: undefined},
  '60': {code: 'jaws', icon: undefined},
  '61': {code: 'chute', icon: undefined},
  '62': {code: 'chain', icon: undefined},
  '63': {code: 'jam', icon: undefined},
  '64': {code: 'chair', icon: undefined},
  '65': {code: 'jail', icon: undefined},
  '66': {code: 'jewish', icon: undefined},
  '67': {code: 'check', icon: undefined},
  '68': {code: 'chef', icon: undefined},
  '69': {code: 'jeep', icon: undefined},
  '70': {code: 'case', icon: undefined},
  '71': {code: 'cat', icon:  <FontAwesomeIcon icon={faCat} size="sm"/>},
  '72': {code: 'cone', icon: undefined},
  '73': {code: 'gum', icon: undefined},
  '74': {code: 'car', icon: undefined},
  '75': {code: 'cola', icon: undefined},
  '76': {code: 'couch', icon: undefined},
  '77': {code: 'cake', icon: undefined},
  '78': {code: 'coffee', icon: undefined},
  '79': {code: 'cup', icon: undefined},
  '80': {code: 'face', icon: undefined},
  '81': {code: 'photo', icon: undefined},
  '82': {code: 'phone', icon: undefined},
  '83': {code: 'foam', icon: undefined},
  '84': {code: 'fire', icon: undefined},
  '85': {code: 'fuel', icon: undefined},
  '86': {code: 'fish', icon: undefined},
  '87': {code: 'fog', icon: undefined},
  '88': {code: 'hifive', icon: undefined},
  '89': {code: 'fob', icon: undefined},
  '90': {code: 'base', icon: undefined},
  '91': {code: 'boat', icon: undefined},
  '92': {code: 'bone', icon: undefined},
  '93': {code: 'beam', icon: undefined},
  '94': {code: 'bear', icon: undefined},
  '95': {code: 'ball', icon: undefined},
  '96': {code: 'peach', icon: undefined},
  '97': {code: 'bike', icon: undefined},
  '98': {code: 'beef', icon: undefined},
  '99': {code: 'baby', icon: undefined},
}
const majorCodec = (value) => {
  return value.split('').reduce((p, c, i) => {
    const e = p.pop();
    if (!e) {
      p.push(c);
    } else if ((/^[0-9]*$/.test(c) === /^[0-9]*$/.test(e)) && 
        (!/^[0-9]*$/.test(c) || e.length < 2)) {
        p.push(e + '' + c);
    } else {
      p.push(e);
      p.push(c);
    }
    return p;
  }, [])
  .map(c => numberMap[c] ? numberMap[c].code : c.trim())
  .join(' ');
  //.map(c => <span>{numberMap[c] ? (numberMap[c].icon ? numberMap[c].icon : numberMap[c].code) : c.trim()}&nbsp;</span>);
 /* 
  .map(c => numberMap[c] ? `%%${numberMap[c]}%%` : c.trim())
  .reduce((p, c, i) => {
    // surrounding converted numbers with %% to join with '-'
    p += (/.*%%$/.test(p) && /^%%.*%%$/.test(c) ? '-' : (p.length ? ' ' : '')) + c;
    return p;
  }, '')
  .replace(/%%/g, '');
  */
};

//module.exports.majorCodec = majorCodec;
export default majorCodec;